/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h4: "h4",
    ol: "ol",
    li: "li",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h4, null, "基于检索的大语言模型分类"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "检索查询内容是什么，何时检索？"), "\n", React.createElement(_components.li, null, "在大语言模型中，我们检索什么？"), "\n", React.createElement(_components.li, null, "如何将检索结果整合到大语言模型中？"), "\n"), "\n", React.createElement(_components.p, null, "技术路线："));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
